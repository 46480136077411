<template>
  <div v-if="crowdfundingAdmin">
    <div class="d-flex">
      <h1 class="headline--md text-bold text-uppercase">Crowdfunding Admin</h1>
      <div class="btn-group">
        <router-link
          tag="button"
          class="btn btn--primary"
          :to="{ name: 'WithdrawAdminUpdate', params: { id: crowdfundingAdmin.id } }"
          >Update</router-link
        >
      </div>
    </div>
    <div v-if="crowdfundingAdmin" class="box box--md box--white mt-16">
      <table class="table table--list three-items">
        <tbody>
          <tr>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Full Name</p>
                <h2 class="headline--md mt-4">{{ crowdfundingAdmin.fullName }}</h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Email</p>
                <h2 class="headline--md mt-4">{{ crowdfundingAdmin.email }}</h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Wallet address</p>
                <h2 class="headline--md mt-4">{{ crowdfundingAdmin.address }}</h2>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Created at</p>
                <h2 class="headline--md mt-4">{{ crowdfundingAdmin.requestAt | moment_lts }}</h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Status</p>
                <h2 v-if="crowdfundingAdmin.status === status.active" class="headline--md mt-4 text-success">
                  {{ crowdfundingAdmin.status | format_status_text }}
                </h2>
                <h2 v-else class="headline--md mt-4 text--gray">
                  {{ crowdfundingAdmin.status | format_status_text }}
                </h2>
              </div>
            </td>
            <td />
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { STATUS } from '@/utils/key-mapping'

export default {
  data() {
    return {
      status: STATUS
    }
  },

  computed: {
    ...mapState('requestTransfer', ['crowdfundingAdmin'])
  },

  methods: {
    ...mapActions('requestTransfer', ['getCrowdfundingAdmin']),

    ...mapMutations('requestTransfer', ['CLEAR_CROWDFUNDING_ADMIN'])
  },

  created() {
    this.CLEAR_CROWDFUNDING_ADMIN()
    this.getCrowdfundingAdmin(this.$route.params.id)
  }
}
</script>
